<template>
	<div style="background: #fff;padding: 15px;">
		<el-form v-if="isEdit" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px">
			<el-form-item label="分组名称:" prop="name">
				<el-input
					v-model="ruleForm.name"
					:disabled="isSystem"
					placeholder="最多输入16个字"
					maxlength="16"
					clearable
					style="width:300px"
				></el-input>
			</el-form-item>
		</el-form>
		<el-button
			v-if="isEdit"
			style="width: 100px;margin-bottom: 20px;"
			type="primary"
			@click="choseGoods()"
		>选择商品</el-button>

		<!-- 表格 -->
		<div class="table-container" style="margin-bottom: 70px;">
			<el-table :data="groupData" v-loading="loading">
				<el-table-column prop="goodsName" label="商品名称" width="300">
					<template slot-scope="scope">
						<div class="dialog-name-content">
							<img :src="imgUrl + scope.row.thumbImgUrl" />
							<div class="right">
								<div class="name">
									<pre
										style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;"
									>{{scope.row.goodsName}}</pre>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="salePrice" label="价格"></el-table-column>
				<el-table-column prop="totalStock" label="库存"></el-table-column>
				<el-table-column label="排序" v-if="!isEdit">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<div style="margin-right: 10px;">{{scope.row.sort}}</div>
							<i class="el-icon-edit-outline" @click="showEditDialog(scope.row)"></i>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="140">
					<template slot-scope="scope">
						<div style="display: flex;align-items: center;">
							<el-button v-if="isEdit" type="text" @click="deleteGood(scope.row)">删除</el-button>
							<el-button v-else type="text" @click="deleteChose(scope.row)">删除</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="footer" v-if="isEdit">
			<el-button style="width:240px" @click="suppilerCansel">取消</el-button>
			<buttonPermissions :datas="'addGroup'">
				<el-button
					style="width:240px;margin:0 10px 0 20px"
					type="primary"
					:loading="loading"
					@click="saveChange"
				>保存</el-button>
			</buttonPermissions>
		</div>
		<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
			<select-produce
				api="activitycouponChoseproductlist"
				:params="['goodsName', 'barCode']"
				:selectedData="groupData"
				@getSelectList="getSelectList"
				:visible.sync="selectProDialog"
				v-if="selectProDialog"
			></select-produce>
		</el-dialog>
		<el-dialog title="编辑排序" :visible.sync="descDialog" width="500px">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item>
					<el-input
						v-model="ruleForm.currentSort"
						placeholder="请输入排序值"
						maxlength="6"
						clearable
						style="width:460px"
						onkeyup="(this.v=function(){this.value=this.value.replace(/[^0-9]+/,'');}).call(this);if (value==0||value=='-'){value =''}"
					></el-input>
				</el-form-item>
			</el-form>

			<!-- 按钮区域 -->
			<div style="text-align: center;margin-top:30px;">
				<el-button style="margin-right:30px;width: 120px;" @click="descDialog=false">关闭</el-button>
				<el-button type="primary" style="width: 120px;" @click="saveSort()">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import {
	productGroupSave,
	getGoodsListByGroupId,
	groupDelGoods,
	updateGoodsOrder
} from '@/api/goods'
// import apiList from "@/api/popGoods.js"
import selectProduce from '../market/coupon/selectMultiPro.vue'
import config from '@/config/index'
import buttonPermissions from '@/components/buttonPermissions';
export default {
	components: {
		buttonPermissions,
		selectProduce
	},
	data () {
		var checkName = (rule, value, callback) => {
			if (!value) {
				return callback(new Error('请输入分组名称'));
			} else if (!value.trim()) {
				return callback(new Error('请输入分组名称'));
			} else {
				return callback()
			}
		};
		var checkValidSort = (rule, value, callback) => {
			if (!value) {
				callback(new Error('请输入排序数值'));
			} else {
				return callback()
			}
		};
		return {
			ruleForm: {
				name: '',
				currentSort: 0
			},
			rules: {
				name: [{
					required: true,
					// message: '请输入分组名称',
					trigger: 'blur',
					validator: checkName
				},
				{
					min: 1,
					max: 16,
					message: '最多输入16个字',
					trigger: 'blur'
				}
				],
				currentSort: [{
					trigger: 'blur',
					validator: checkValidSort
				}]
			},
			groupData: [],
			searchKey: '',
			loading: false,
			editVisable: false,
			groupName: "",
			eidtGroupData: {},
			exportUrl: config.EXPORT_URL,
			selectProDialog: false,
			groupId: 0,
			isEdit: true, //是否是编辑
			descDialog: false,
			sortRow: null,
			imgUrl: config.IMG_BASE,
			isSystem: false
		};
	},
	created () {
		this.isSystem = (+this.$route.query.isSystem || 0) == 1;
		this.groupId = +this.$route.query.groupId || 0;
		this.ruleForm.name = this.$route.query.name || "";
		var type = this.$route.query.isEdit || 1;
		this.isEdit = type == 1 ? true : false;
		if (this.groupId) {
			this.getList();
		}
	},
	methods: {
		// 获取列表数据
		async getList () {
			try {
				this.loading = true
				let data = {
					id: this.groupId
				}
				let result = await getGoodsListByGroupId(data);
				this.groupData = result.data || [];
				// this.groupData.map(item=>{
				// 	item.order = 0;
				// 	return item;
				// })
			} catch (e) {
				console.log(e)
			} finally {
				this.loading = false
			}

		},
		goodsSearch () {
			this.getList();
		},
		eidtGroup (item) {
			this.eidtGroupData = item;
			this.ruleForm.name = item.GroupName ? item.GroupName : ''
			this.editVisable = true
		},
		//编辑时 删除
		deleteGood (row) {
			var index = 0;
			this.groupData.map((item, ix) => {
				if (item.id === row.id) {
					index = ix;
				}
				return item;
			})
			this.groupData.splice(index, 1);
		},
		//非编辑时的 删除
		deleteChose (record) {
			this.$confirm('是否确认继续删除?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(async () => {

				let result = await groupDelGoods({
					goodsId: record.id,
					groupId: this.groupId
				})
				if (result.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '删除成功!'
					});
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: result.alertMsg ? result.alertMsg : '删除失败'
					});
				}

			}).catch(() => {
				this.$message({
					showClose: true,
					type: 'info',
					message: '已取消删除'
				});
			}).finally(() => {
				setTimeout(() => {
					this.goodsSearch();
				}, 500)

			})


		},
		async saveChange () {
			this.$refs['ruleForm'].validate(async (valid) => {
				if (valid) {
					// if (!this.groupData.length) {
					// 	this.$message({
					// 		showClose: true,
					// 		type: 'error',
					// 		message: '请选择分组商品'
					// 	});
					// 	return;
					// }
					try {
						this.loading = true
						let list = [];
						this.groupData.map(item => {
							list.push(item.id);
							return item;
						})
						let data = {
							groupName: this.ruleForm.name,
							goodsIdList: list
						}
						if (this.groupId) {
							data.groupId = this.groupId
						}
						let result = await productGroupSave(data)
						if (result.success) {
							this.$message({
								showClose: true,
								type: 'success',
								message: '保存成功!'
							});
							this.editVisable = false;
						} else {
							this.$message({
								showClose: true,
								type: 'error',
								message: result.alertMsg ? result.alertMsg : '删除失败'
							});
						}

					} catch (e) {
						console.log(e)
					} finally {
						this.loading = false
						this.suppilerCansel();
					}
				} else {
					return false;
				}
			})


		},
		//获取选择商品的列表
		getSelectList (data) {
			var list = this.groupData;
			data.map(item => {
				item.order = 0;
				var isHave = false;
				list.map(item2 => {
					if (item.id == item2.id) {
						isHave = true;
					}
					return item2;
				})
				if (!isHave) {
					this.groupData.push(item);
				}
				return item;
			})
			this.selectProDialog = false;
		},
		choseGoods () {
			this.selectProDialog = true;
		},
		// 取消
		suppilerCansel () {
			this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
				(item) => {
					return item.path != this.$route.path;
				}
			);
			this.$router.push({
				path: '/goods/goodGroup'
			});
		},
		showEditDialog (row) {
			this.sortRow = row;
			this.ruleForm.currentSort = row.sort;
			this.descDialog = true;
		},
		async saveSort () {
			try {
				let data = {
					goodsId: this.sortRow.id,
					groupId: this.groupId,
					sort: +this.ruleForm.currentSort
				};
				let res = await updateGoodsOrder(data);
				if (res.success) {
					this.$message({
						showClose: true,
						type: 'success',
						message: '商品排序修改成功!'
					});
				} else {
					this.$message({
						showClose: true,
						type: 'error',
						message: '操作失败!'
					});
				}
			} catch (e) {
				//TODO handle the exception
			} finally {
				this.descDialog = false;
				this.ruleForm.currentSort = 0;
				this.sortRow = null;
				this.getList();
			}
		}
	}
}
</script>

<style lang="less" scoped>
.exportBtn {
	position: relative;
}

.exportInput {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	width: 90px;
}

#inputsId {
	display: inline-block;
	height: 35px;
	width: 90px;
}

.footer {
	position: fixed;
	width: 100%;
	background: #fff;
	bottom: 0;
	text-align: center;
	padding: 20px 0;
	z-index: 100;
	box-shadow: 13px 1px 6px #999;
}

.dialog-name-content {
	margin: 10px 0;
	display: flex;
	flex-direction: row;

	img {
		width: 60px;
		height: 60px;
		object-fit: cover;
		margin-right: 10px;
	}
}
</style>
